<template>
  <canvas id="three"></canvas>
</template>
<style scoped>
#three {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
</style>
